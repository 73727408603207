import { environment } from "src/config/environment";
import { Country } from "src/interfaces/country";
import passwordlessSVG from "../assets/social-icones/passwordless.svg";
import facebookSVG from "../assets/social-icones/facebook.svg";
import googleSVG from "../assets/social-icones/google.svg";
import { Language } from "src/interfaces/language";

type SocialLoginEnabledType = Record<string, string[]>;

export type ProviderType = "google" | "facebook" | "passwordless";

export interface ICookieParams {
  clientId: string;
  country: Country;
  company: string;
  language: Language;
  redirectUri: string;
  provider: string;
  sso?: string;
}

export const providers = {
  passwordless: {
    image: passwordlessSVG,
    alt: "Passwordless",
    provider: "BindID",
  },
  facebook: {
    image: facebookSVG,
    alt: "Facebook",
    provider: "Facebook",
  },
  google: {
    image: googleSVG,
    alt: "Google",
    provider: "Google",
  },
};

export const isSocialLoginEnable = (
  country: Country,
  clientId: string
): boolean => {
  const socialLoginEnabled = JSON.parse(
    environment.socialLoginEnabled
  ) as SocialLoginEnabledType;
  const enable = socialLoginEnabled[clientId]?.find((item) => item === country);

  return enable !== undefined;
};

export const getProviderName = (
  provider: ProviderType,
  country: string
): string => {
  return provider === "passwordless" && country === "mx"
    ? "BindIDNoElo"
    : providers[provider].provider;
};

export const getFederationUrl = (
  country: string,
  providerName: string,
  clientId: string
): string => {
  const cognitoDomain =
    country === "br"
      ? environment.cognitoBrDomain
      : environment.cognitoLatamDomain;
  const redirectUrl = `https://${window.location.hostname}/social-sign-in`;
  return `${cognitoDomain}/oauth2/authorize?identity_provider=${providerName}&redirect_uri=${redirectUrl}&response_type=CODE&client_id=${clientId}&scope=openid`;
};
